<template>
  <div class="flex-1 flex flex-col max-h-full" v-allow:auth="'reportTemplates.read'">
    <sub-header
      :handleSearchFilter="handleSearchFilter"
      :showBtn="checkPermission('reportTemplates.create')"
      :handleClick="createCustomReport"
      :buttonText="$t('report_templates.addOrcaCustomReport')"
    />
    <div
      class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"
    >
      <Table
        id="templatesTable"
        :columns="columnOption"
        :key="pageData.total"
        :rows="templates"
        :isLoading="isLoading"
        :filters="serverParams"
        :filterOptions="columnFilterOptions"
        :filterOptionsAsync="columnFilterOptionsAsync"
        @per-page-change="onPageChange($event, true)"
        @page-change="onPageChange($event)"
        @column-filter="onColumnFilters($event)"
        :paginationOptions="paginationOptions"
        :totalRows="pageData.total"
        @filters-clear="clearFilters"
        sortingEnabled
        @sort-change="onSortChange"
        @search-filter="getSearchOptionsTick($event)"
      >
        <template #is_custom_logo="customData">
          <div class="logo-success" v-if="customData.customData.has_custom_logo">
              <font-awesome-icon icon="check" class="w-3 h-3 text-white"></font-awesome-icon>
          </div>

          <div class="logo-danger" v-else>
              <font-awesome-icon icon="cross" class="w-3 h-3 text-white"></font-awesome-icon>
          </div>
        </template>

        <template #link="customData">
          <a :href="`${templateBaseUrl}${customData.customData.custom_logo}`" 
            target="_blank" rel="noreferrer" class="text-blue-600 no-underline font-medium"> 
            {{ customData.customData.custom_logo }} 
          </a>
        </template>

        <template #actions="customData">
          <div class="flex gap-2 cursor-pointer">
              <button
                class="h-8 w-4 disabled:opacity-50 mx-2"
                @click="editTemplate(customData.customData.id)"
                :disabled="!checkPermission('reportTemplates.edit')"
                v-tippy
                :content="$t('report_templates.edit')"
              >
                <font-awesome-icon
                  icon="edit"
                  class="text-blue-600 text-lg fa-3x"
                  :class="
                    customData.customData
                      ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                      : 'text-gray-500'
                  "
                />
              </button>

              <button
                class="h-8 w-4 disabled:opacity-50 mx-2"
                @click="deleteTemplate(customData.customData.id)"
                :disabled="!checkPermission('reportTemplates.edit')"
                v-tippy
                :content="$t('report_templates.delete')"
              >
                <font-awesome-icon
                  icon="trash"
                  class="text-red-600 text-lg fa-3x"
                  :class="
                    customData.customData
                      ? 'text-red-500 hover:text-red-600 hover:underline cursor-pointer'
                      : 'text-gray-500'
                  "
                />
              </button>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Table from "@shared/dashboard-components/table-large";
import axios from "@/axios";
import { debounce } from "lodash";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { checkPermission } from "@shared/utils/functions";
import { EventBus } from "@/main.js";
import { DateTime } from "luxon";
import { FormatDateNew } from "@/plugins/functions";
import { mapActions } from "vuex";

export default {
  name: "check-admin",
  title: "templates",

  components: {
    SubHeader,
    Table,
  },
  props: {},
  data: () => ({
    searchTimeout: null,
    searchLoading: {},
    columnFilterOptionsAsync: null,
    isEntityTypesLoading: false,
    columns: [
      {
        field: "client_name",
        config: { filter: true },
        sortable: true,
      },
      {
        field: "package_name",
        tdClass: "truncate max-w-xs",
        config: { filter: true },
        sortable: true,
      },
      {
        field: "template_name",
        tdClass: "truncate max-w-xs",
        width: "120px",
        config: { filter: true },
        sortable: true,
      },
      { 
        field: "is_custom_logo",
        tdClass: "truncate max-w-xs" , 
        width: "185px", 
        config: { filter: false, type: "is_custom_logo" },
        sortable: false
      },
      {
        field: "custom_logo",
        tdClass: "truncate max-w-xs",
        width: "185px",
        config: { filter: false, type: "link" },
        sortable: false,
      },
      {
        field: "report_style_name",
        tdClass: "truncate max-w-xs",
        width: "185px",
        config: { filter: false },
        sortable: false,
      },
      { 
        field: "actions",
        tdClass: "truncate max-w-xs" , 
        width: "185px", 
        config: { filter: false, type: "actions" },
        sortable: false
      },

      // {"label":"Automatic/Manual","field":"is_automatic"},
    ],
    serverParams: null,
    templates: [],
    isLoading: false,
    isCloning: false,
    searchValue: "",
    paginationOptions: {
      enabled: true,
      mode: "remote",
      perPage: 10,
    },
    pageData: {
      total: 0,
    },
    currentPageData: {
      page: 1,
      count: 10,
    },
    searchParams: {
      package: "",
    },
    sortParams: null,
    templateBaseUrl:'https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/',
  }),
  async created() {
    EventBus.$on("fetchTemplates", () => {
      this.fetchTemplates();
    });
  },
  computed: {
    columnFilterOptions() {
      let cols = this.getFilterOptions(this.columns, this.templates);
      return cols;
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        label: this.$t(`report_templates.template_list_columns.${el.field}`),
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
  },

  async mounted() {
    await this.fetchTemplates();
  },

  methods: {
    ...mapActions(["fetchEntityTypeList"]),
    FormatDateNew,
    checkPermission,
    currentPageDataOpt() {
      return { ...this.currentPageData, perPage: this.currentPageData.count };
    },
    async createCustomReport() {
      this.$router.push("/report-templates/create");
    },
    
    editTemplate(id) {
      this.$router.push({
          path: `/report-templates/edit/${id}`,
      });
    },

    async deleteTemplate(id) {
        const data = await axios.delete(`/tenant-templates/assigned-templates/${id}`);
        console.log(data);
        if (data.status === 200  && data.data)
            this.$toast.success(this.$t('report_templates.template_delete_success'));
        await this.fetchTemplates();
        // this.reportData = data.data;
    },

    async fetchTemplates() {
      this.isLoading = true;
      let filterParams = {};

      const getFilterValue = (key) => {
        if (["source"].includes(key)) {
          return this.serverParams[key]["value"];
        } else return this.serverParams[key]["name"];
      };
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.serverParams[key]
            ? getFilterValue(key)
            : delete filterParams[key];
        });
        if (filterParams.created_at) {
          filterParams.created_at = DateTime.fromFormat(
            filterParams.created_at,
            "dd MMM yyyy"
          )
            .plus({ days: 0 })
            .toISODate();
        }
        if (filterParams.last_updated) {
          filterParams.last_updated = DateTime.fromFormat(
            filterParams.last_updated,
            "dd MMM yyyy"
          )
            .plus({ days: 0 })
            .toISODate();
        }
      }
      let url = `/tenant-templates/assigned-templates`;
      let { data } = await axios.post(url, {
          ...this.currentPageData,
          ...filterParams,
          ...this.sortParams,
      });
      try {
        // const formatDate = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
        let templatesData = data?.data?.map((el) => {
          if (el.created_at || el.last_updated) {
            el.created_at = FormatDateNew(new Date(el.created_at));
            el.last_updated = FormatDateNew(new Date(el.last_updated));
            return el;
          }
          if (el.parameters && el.parameters !== "null") {
            let data = JSON.parse(el.parameters);
            return { ...el, parameters: data.parameters };
          } else {
            return { ...el };
          }
        });
        this.templates = templatesData;
        this.pageData = data?.page;
        this.paginationOptions.perPage = data?.page.per_page;
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.isLoading = false;
    },

    

    async getSearchOptions(payload) {
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          const requestData = {
            ...this.serverParams,
            [payload.column.query_key || payload.column.field]: payload.value,
          };
          const { data } = await axios.post(
            `/tenant-template/assigned-templates`, requestData);
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columns,
            data.data
          );
        } catch (error) {
          console.log("getSearchOptions :>> ", error);
        }

        this.$set(this.searchLoading, payload.column.field, false);
      }
    },

    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },

    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },

    handleSearchFilter: debounce(function(value) {
      this.searchValue = value;
    }, 1000),

    async onColumnFilters({ columnFilters }) {
      this.currentPageData.page = 1;
      this.paginationOptions.perPage = this.currentPageData.count;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchTemplates();
    },

    handleFilterResult(data) {
      this.searchParams.package = data;
    },

    async clearFilters() {
      this.serverParams = null;
      await this.fetchTemplates();
    },
    
    async onSortChange(data) {
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        this.sortParams = {
          // sort: data.field + ":" + data.type,
          order_by:  data.field,
          order: data.type,
        };
        await this.fetchTemplates();
      } else {
        this.sortParams = null;
        await this.fetchTemplates();
      }
    },

    async onPageChange(data, resetPage = false) {
      this.currentPageData = {
        page: data.currentPage,
        count: data.currentPerPage,
      };
      if (resetPage) this.currentPageData.page = 1;
      if (!resetPage) {
        await this.$nextTick();
        const scrollTarget = document.getElementById("templatesTable");
        if (scrollTarget) {
          scrollTarget.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      await this.fetchTemplates();
    },
  },
};
</script>

<style lang="scss">
.tooltip-text {
  display: none;
  position: absolute;
  z-index: 999 !important;
  width: auto;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  display: block;
}

#right {
  left: 70px;
  top: -30px;
}

.logo-success,
.login-danger {
  @apply bg-green-600;
  @apply rounded-full;
  @apply w-4;
  @apply h-4;
  @apply mx-auto;
  padding: 1px;
}

// .modalContent .modalContent__footer{
//     display:none !important;
// }

// #bottom {
//   top: 25px;
//   left: -50%;
// }

// #bottom::before {
//   top: -5%;
//   left: 45%;
// }

// #left {
//   top: -8px;
//   right: 120%;
// }

// #left::before {
//   top: 35%;
//   left: 94%;
// }

// #right {
//   top: -8px;
//   left: 120%;
// }

// #right::before {
//   top: 35%;
//   left: -2%;
// }

.hover-text {
  position: relative;
}
</style>
